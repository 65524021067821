html, body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0 auto;
  width: 100vw;
}

html {
  overflow-x: hidden;
}

* {
  box-sizing: border-box; 
}

h2, h3, p {
  margin: 0;
  padding: 0;
}

#beignet {
  position: relative;
  width: 100%;
}