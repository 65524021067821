.bs {
  &-menu--button {
    height: 30px;
    position: absolute;
    left: 20px;
    opacity: 0;
    top: 20px;
    width: 35px;
    z-index: 7777;

    @media (min-width: 768px) {
      left: 30px;
      top: 30px;
    }

    @media (min-width: 1024px) {
      left: 25px;
      top: 25px;
    }
    
    @media (min-width: 1440px) {
      height: 30px;
      left: 35px;
      top: 35px;
      width: 40px;
    }
    
    @media (min-width: 1900px) {
      height: 30px;
      left: 50px;
      top: 35px;
      width: 40px;
    }

    &:hover {
      cursor: pointer;

      .bs-menu--button_bar {
        background: var(--bs-orange);

        &[data-position="1"],
        &[data-position="2"],
        &[data-position="3"] {
          width: 100%;
        }
      }
    }

    &[data-active="true"] {
      .bs-menu--button_bar {
        background: var(--bs-black);

        &[data-position="1"] {
          top: 50%;
          transform: translateY(-50%) rotateZ(-45deg);
          transform-origin: center;
          width: 100%;
        }

        &[data-position="2"] {
          opacity: 0;
          width: 100%;
        }
        
        &[data-position="3"] {
          bottom: 45%;
          transform: translateY(-45%) rotateZ(45deg);
          transform-origin: center;
          width: 100%;
        }
      }
    }

    &_bar {
      background: var(--bs-black);
      height: 2px;
      transition: all .25s ease-out;

      &[data-position="1"] {
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;
      }

      &[data-position="2"] {
        position: absolute;
        right: 0;
        top: 40%;
        transform: translateY(-50%);
        width: 100%;

        @media (min-width: 1024px) {
          top: 50%;
        }
      }

      &[data-position="3"] {
        bottom: 20%;
        position: absolute;
        right: 0;
        width: 100%;

        @media (min-width: 1024px) {
          bottom: 0;
        }
      }
    }
  }
  
  &-menu {
    @include AzoSansUber;
    background: var(--bs-yellow);
    left: 0;
    letter-spacing: 1px;
    margin: 0;
    opacity: 0;
    overflow: hidden;
    padding-bottom: 50px;
    position: absolute;
    top: -100%;
    transition: all .5s ease-out;
    width: 100vw;
    z-index: 999;

    &[data-active="true"] {
      height: 100vh;
      opacity: 1;
      top: 0;
    }

    &--list {
      list-style: none;
      margin: 0;
      padding: 0;
      width: 100%;

      &_anchor {
        color: var(--bs-orange-dark);
        font-size: 28px;
        display: inline-block;
        padding: 0 0 20px 0;
        text-decoration: none;
        text-transform: uppercase;

        &:hover {
          color: var(--bs-black);
          cursor: pointer;
        }

        @media (min-width: 320px) {
          padding: 0 0 10px 0;
        }

        @media (min-width: 375px) {
          padding: 20px 0;
        }

        @media (min-width: 768px) {
          font-size: 16px;
        }

        @media (min-width: 1024px) {
          font-size: 28px;
        }
        
        @media (min-width: 1280px) {
          font-size: 48px;
        }
      }

      &_heading {
        color: var(--alfa-white);
        font-size: 16px;
        letter-spacing: 2px;
        margin: 0;
        text-transform: uppercase;
        
        @media (min-width: 768px) {
          font-size: 24px;
        }
        
        @media (min-width: 1440px) {
          font-size: 36px;
        }

        &:first-child {
          padding: 0 0 10px;
        }
      }

      &_wrapper {
        // border: thin solid red;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        left: 50%;
        position: absolute;
        top: 15%;
        transform: translateX(-50% );
        text-align: center;
        width: 80vw;

        @media (min-width: 720px) {
          flex-direction: row;
          width: 75vw;
        }

        @media (min-width: 768px) {
          flex-direction: column;
          margin: 20px auto 60px;
          width: 60vw;
        }

        @media (min-width: 1024px) {
          flex-direction: row;
          margin: 70px auto;
          width: 85vw;
        }
        
        @media (min-width: 1336px) {
          flex-direction: row;
          margin: 70px auto;
          width: 70vw;
        }
        
        @media (min-width: 1440px) {
          flex-direction: row;
          margin: 0 auto;
          width: 45vw;
        }
        
        @media (min-width: 1920px) {
          flex-direction: row;
          margin: 70px auto;
          width: 45vw;
        }
      }
      
      &_inner {
        // border: thin solid red;
        display: flex;
        justify-content: space-between;
        margin: 0 0 30px 0;
        width: 100%;
      }
    }
  }
}