.bs {
  &-products {    
    left: -50px;
    opacity: 0;
    overflow: hidden;
    padding: 0;
    position: relative;
    width: 100%;

    &_image {
      display: flex;
      height: auto;
      margin: 0 auto 10px;
      width: 75%;
    }

    @media (min-width: 1024px) {
      margin: 60px auto 10px;
      padding: 0;
    }

    .bs-action {
      padding: 35px 0 55px;

      @media (min-width: 1024px) {
        padding: 55px 0 75px;
      }
    }

    &--header {
      @include AzoSansUber;
      font-size: 34px;
      color: var(--bs-black);
      text-align: center;
      padding: 0;

      @media (min-width: 1024px) {
        font-size: 48px;
      }

      &:nth-child(1) {
        color: var(--bs-orange-dark);
      }
      
      &:nth-child(2) {
        color: var(--bs-orange-dark);
      }
      
      &:nth-child(3) {
        color: var(--bs-orange);
      }
    }

    &--slider {
      // background: linear-gradient(90deg, var(--bs-white) 0%, var(--bs-yellow) 40%);
      height: 575px;
      padding: 0;
      position: relative;

      &_prev {
        left: 25px;
      }
      
      &_next {
        right: 25px;
      }

      &_label {
        align-items: center;
        background: rgba(255, 255, 255, .9);
        bottom: 0;
        display: flex;
        height: 80px;
        padding: 0 20px;
        position: absolute;
        width: 100%;
      }
      
      &_text {
        @include AzoSansUber;
        background-image: linear-gradient(-180deg, var(--bs-orange-dark), var(--bs-yellow));
        background-clip: text;
        color: transparent;
        font-size: 28px;
        line-height: 1;
      }
      
      &_prev,
      &_next {
        background: var(--bs-white);
        border-radius: 100%;
        border: 0;
        color: var(--bs-black);
        height: 75px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        transition: all .35s ease-out;
        width: 75px;
        z-index: 7;

        &:hover {
          background: var(--bs-yellow);
          cursor: pointer;
        }
      }

      &_content {
        // border: thin solid rgb(0, 47, 255);
        display: flex;
        transition: all .35s ease-out;
        left: 0;
        height: 100%;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: calc(6 * 550px);
      }

      &_card {
        // background: rgb(255, 0, 128);
        background-size: cover;
        border-radius: 30px;
        flex-grow: 1;
        justify-content: space-evenly;
        height: 100%;
        margin: 0 5px;
        max-height: 550px;
        // min-width: 360px;
        outline: thin solid white;
        position: relative;
        transition: all .35s ease-out;
        z-index: 1;

        &:hover {
          transform: scale(1.05);
          z-index: 7;
        }
      }
    }
  }
}