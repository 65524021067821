.bs {
  &-footer {
    overflow: hidden;
    padding: 50px 0;
    position: relative;

    &--content {
      display: flex;
      flex-direction: column;
      left: -25px;
      position: relative;
      opacity: 0;

      @media (min-width: 1024px) {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        position: relative;
      }
      
      @media (min-width: 1280px) {
        display: grid;
        grid-template-columns: 2fr 1fr 1fr 1fr 1fr 2fr;
        position: relative;
      }
    }

    &--list_wrapper {
      display: flex;
      flex-direction: column;
      grid-template-columns: unset;

      @media (min-width: 768px) {
        display: grid;
        grid-template-columns: repeat(2, 1fr [col-start]);
        margin: 0 auto;
        width: 85%;
      }
      
      @media (min-width: 1024px) {
        display: grid;
        grid-template-columns: repeat(3, 1fr [col-start]);
      }
    }

    &--column {
      // border: thin solid red;

      @media (min-width: 1024px) {
        &.logo {
          grid-column-start: 1;
          grid-column-end: span 2;
        }
  
        &:nth-child(4) {
          grid-column-start: 2;
        }
      }
      
      @media (min-width: 1280px) {
        &.logo {
          grid-column-start: 1;
          grid-column-end: span 1;
        }

        &:nth-child(2) {
          grid-column-start: 2;
        }
        
        &:nth-child(3) {
          grid-column-start: 3;
        }
        
        &:nth-child(4) {
          grid-column-start: 4;
        }
      }

      .bs-logo {
        padding: 0;
        margin: 0 auto;
        width: 75%;
      }

      .bs-logo--svg {
        padding: 0;
        margin: 0 auto;
        width: 100%;

        @media (min-width: 1024px) {
          // width: 400px;
        }
      }
    }

    &--list {
      list-style: none;
      margin: 0;
      padding: 40px 50px 0;

      @media (min-width: 1024px) {
        margin: 0;
        padding: 0 0 0 0;
      }
      
      @media (min-width: 1440px) {
        margin: 0;
        padding: 0 0 0 40px;
      }

      &_item {
        @include AzoSansRegular;
        font-size: 14px;
        color: var(--bs-black);
        display: block;
        padding: 10px 0;
        text-decoration: none;

        @media (min-width: 1024px) {
          font-size: 12px;
        }

        &.header {
          @include AzoSansBold;
          font-size: 24px;
          text-transform: uppercase;

          @media (min-width: 1024px) {
            font-size: 12px;
          }
        }
        
        &.important {
          @include AzoSansBold;
          font-size: 24px;
          text-transform: uppercase;
          padding: 5px 0;

          @media (min-width: 1024px) {
            font-size: 20px;
          }

          &:nth-child(1) {
            padding: 10px 0 0;
          }
        }

        .bs-action  {
          padding: 10px 0;
          text-align: left;
        }
      }
    }

    &--copyright {
      @include AzoSansBold;
      border-top: thin solid var(--bs-black-20);
      margin: 25px 0 0;
      opacity: 0;
      text-transform: uppercase;
      padding: 25px 0 0;
      text-align: center;

      &_company,
      &_ssm {
        color: var(--bs-black);
        font-size: 13px;
        padding: 5px 0;
        text-decoration: none;
      }
      
      &_ssm {
        &:hover {
          opacity: 1 !important;
        }
      }
    }
  }
}