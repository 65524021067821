:root {
  --bs-white: #ffffff;
  --bs-white-85: rgba(219, 249, 255, .85);
  --bs-black: #000;
  --bs-black-20: rgba(0, 0, 0, .20);
  --bs-yellow: #ffba16;
  --bs-yellow-light: #ffcf0f;
  --bs-orange: #ff790c;
  --bs-orange--light: rgba(255, 116, 0, .50);
  --bs-orange-dark: #fc5609;
}