.bs {
  &-announcement {
    // border: thin solid white;
    margin: 60px 0;
    padding: 0;
    position: relative;
    text-align: center;
    width: 100%;
    z-index: 10;

    @media (min-width: 768px) {
      margin: 150px 0;
    }

    @media (min-width: 1024px) {
      margin: 100px 0 50px;
      padding: 0;
    }
    
    @media (min-width: 1024px)
      and (min-height: 600px) {
      margin: 20px 0 50px;
      padding: 0;
    }
    
    @media (min-width: 1280px) {
      padding: 50px 0;
    }

    @media (min-width: 1280px)
      and (min-height: 800px) {
      margin: 20px 0 50px;
      padding: 0;
    }

    &--text {
      @include AzoSansUber;
      color: var(--bs-orange-dark);
      display: flex;
      flex-direction: column;
      font-size: 40px;
      margin: 0;
      line-height: 1.15;
      letter-spacing: 5px;
      padding: 0;

      &_now {
        margin-right: 50px;
        opacity: 0;
      }
      
      &_open {
        // margin-left: 50px;
        // opacity: 1;
      }

      @media (min-width: 320px) {
        font-size: 55px;
      }

      @media (min-width: 540px) {
        font-size: 80px;
      }

      @media (min-width: 768px) {
        font-size: 125px;
      }
      
      @media (min-width: 840px) {
        font-size: 150px;
      }

      @media (min-width: 1024px) {
        background-image: linear-gradient(90deg, var(--bs-orange-dark), var(--bs-yellow));
        background-clip: text;
        color: transparent;
        display: unset;
        font-size: 150px;
        flex-direction: row;
        letter-spacing: 4px;
        opacity: 1;

        &_now {
          margin-right: 0;
          opacity: 1;
        }
        
        &_open {
          // margin-left: 150px;
          // opacity: 1;
        }
      }

      @media (min-width: 1024px)
        and (min-height: 600px) {
          font-size: 125px;
      }

      @media (min-width: 1280px) {
        font-size: 200px;
      }

      @media (min-width: 1024px)
        and (min-height: 800px) {
          font-size: 155px;
      }
      
      @media (min-width: 1440px) {
        font-size: 250px;
      }
      
      // @media (min-width: 1900px) {
      //   font-size: 250px;
      // }
    }
    
    &--beignet {
      left: 55%;
      opacity: 0;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 175px;

      @media (min-width: 768px) {
        width: 380px;
      }
      
      @media (min-width: 1024px) {
        width: 300px;
      }
      
      @media (min-width: 1024px)
        and (min-height: 800px) {
          width: 250px;
      }
      
      @media (min-width: 1280px) {
        width: 300px;
      }
      
      @media (min-width: 1440px) {
        width: 300px;
      }
      
      @media (min-width: 1900px) {
        width: 400px;
      }
    }
  }
}