.bs {
  &-character {
    background: var(--bs-yellow);
    border-radius: 100%;
    display: none;
    height: 50px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    right: 15px;
    top: 10px;
    width: 50px;
    z-index: 777;

    @media (min-width: 1024px) {
      right: 20px;
      top: 20px;
    }

    &:hover {
      .bs-character--thumb {
        top: 60%;
      }
    }

    &--thumb {
      background: url('../images/bs-btn-shop-char.png') top center no-repeat;
      background-size: cover;
      height: 40px;
      position: absolute;
      left: 50%;
      top: 85%;
      transform: translate(-50%, -50%);
      transition: all .35s ease-out;
      width: 40px;
    }
  }
}