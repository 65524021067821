.bs {
  &-split {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    overflow: hidden;
    padding: 20px 0 25px;

    @media (min-width: 768px) {
      padding: 50px 0 50px;
    }

    @media (min-width: 1024px) {
      flex-direction: row;
      padding: 100px 0;
    }
    
    @media (min-width: 2560px) {
      margin: 0 auto;
      width: 85vw;
    }

    &[data-direction="right"] {
      flex-direction: column;

      @media (min-width: 1024px) {
        flex-direction: row-reverse;
      }
    }

    &--poster {
      // border: thin solid red;
      border-radius: 100px;
      height: 300px;
      opacity: 0;
      margin: 0 auto;
      width: 80%;

      @media (min-width: 768px) {
        width: 75%;
      }

      @media (min-width: 1024px) {
        height: 350px;
        margin: 0 auto;
        max-width: 550px;
        width: 45%;
      }
      
      @media (min-width: 1440px) {
        height: 400px;
        margin: 0 auto;
        max-width: 650px;
        width: 50%;
      }

      &[data-section='about'] {
        background: url('../images/bs-story.jpg') center center no-repeat;
        background-size: 190%;

        @media (min-width: 1024px) {
          background-size: 120%;
        }
      }
      
      &[data-section='process'] {
        background: url('../images/bs-process.jpg') center center no-repeat;
        background-size: 190%;

        @media (min-width: 1024px) {
          background-size: 120%;
        }
      }
      
      &[data-direction="left"] {
        transform: translateX(-50px) rotateZ(-3deg);
      }
      
      &[data-direction="right"] {
        transform: translateX(150px) rotateZ(3deg);
      }
    }

    &--text {
      // border: thin solid blue;
      opacity: 0;
      margin: 40px auto 0;
      width: 80%;

      @media (min-width: 768px) {
        width: 75%;
      }

      @media (min-width: 1024px) {
        padding-inline-end: 0px;
        transform: translateX(50px);
        width: 40%;
      }
      
      @media (min-width: 1440px) {
        padding-inline-end: 150px;
        transform: translateX(50px);
        width: 40%;
      }

      &[data-direction="right"] {
        @media (min-width: 1024px) {
          padding-inline-end: 0;
          padding-inline-start: 0;
          transform: translateX(-50px);
        }
        
        @media (min-width: 1440px) {
          padding-inline-end: 0;
          padding-inline-start: 150px;
          transform: translateX(-50px);
        }
      }

      &_label {
        @include AzoSansBold;
        font-size: 12px;
        text-transform: uppercase;

        @media (min-width: 768px) {
          font-size: 16px;
        }
        
        @media (min-width: 840px) {
          font-size: 18px;
          margin: 10px 0;
        }
        
        @media (min-width: 1280px) {
          font-size: 16px;
          margin: 0;
        }
      }
      
      &_heading {
        @include AzoSansUber;
        background-image: linear-gradient(-180deg, var(--bs-orange-dark), var(--bs-yellow));
        background-clip: text;
        color: transparent;
        font-size: 32px;
        text-transform: uppercase;
        padding: 10px 0;

        @media (min-width: 840px) {
          font-size: 40px;
          margin: 10px 0;
        }
      }
      
      &_desc {
        @include AzoSansRegular;
        font-size: 14px;
        line-height: 1.5;

        @media (min-width: 768px) {
          font-size: 16px;
          line-height: 1.5;
        }
      }
    }
  }
}