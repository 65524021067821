.bs {
  &-modal {
    align-items: center;
    background: linear-gradient(-180deg, var(--bs-white) 0%, var(--bs-yellow) 40%);
    border-radius: 20px;
    display: flex;
    // height: 50vh;
    left: 50%;
    position: fixed;
    top: 50%;
    transform: translate(-50%, -50%);
    // width: 50vw;
    z-index: 777777777;

    &--exit {
      background: var(--bs-black);
      border-bottom-left-radius: 10px;
      height: 60px;
      right: 0px;
      top: 0px;
      position: absolute;
      width: 60px;
      z-index: 21;

      svg {
        fill: var(--bs-orange-dark);
      }
    }

    &--content {
      background: var(--bs-black);
      border: 10px solid var(--bs-black);
      border-radius: 10px;
      margin: 0 auto;

      &_video {
        // object-fit: cover;
        height: 75vh;
        margin: auto;
      }
    }
  }
}