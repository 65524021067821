.bs {
  &-media {
    background: linear-gradient(180deg, var(--bs-yellow) 0%, var(--bs-orange) 95%, var(--bs-white) 100%);
    overflow: hidden;
    padding: 0 0 200px 0;
    position: relative;
    width: 100%;

    @media (min-width: 390px) {
      padding: 0 0 100px 0;
    }
    
    @media (min-width: 768px) {
      padding: 0 0 150px 0;
    }
    
    @media (min-width: 1024px) {
      padding: 0 0 400px 0;
    }
    
    @media (min-width: 1280px) {
      padding: 0 0 500px 0;
    }

    &--sugar {
      background: url('../images/bs-sugar-bg-break-1.png') 55% 30% no-repeat;
      // border: thin solid red;
      bottom: 0;
      left: 0;
      position: absolute;
      height: 100vh;
      width: 100vw;
      z-index: 5;

      @media (min-width: 390px) {
        background: url('../images/bs-sugar-bg-break-1.png') 20% 30% no-repeat;
      }

      @media (min-width: 768px) {
        background: url('../images/bs-sugar-bg-break-1.png') 0 10% no-repeat;
        bottom: -100px;
      }

      @media (min-width: 1024px) {
        background: url('../images/bs-sugar-bg-break-1.png') 75% 0% no-repeat;
        bottom: -200px;
      }
      
      @media (min-width: 1280px) {
        background: url('../images/bs-sugar-bg-break-1.png') 100% 20% no-repeat;
        bottom: 0;
      }
      
      @media (min-width: 1900px) {
        background: url('../images/bs-sugar-bg-break-1.png') 100% -80% no-repeat;
        bottom: 0;
      }
    }

    &--header {
      // border: thin solid white;
      opacity: 0;
      padding: 50px 0;
      position: relative;
      right: -50px;

      @media (min-width: 840px) {
        padding: 75px 0;
      }

      &_text,
      &_desc {
        @include AzoSansUber;
        color: var(--bs-orange-dark);
        font-size: 42px;
        line-height: 1;
        margin: 0;
        padding: 0;
        position: relative;
        text-align: center;

        @media (min-width: 1024px) {
          font-size: 60px;
        }
      }
    }

    &--video {
      background: url('../images/bs-video-poster-static-2.jpg') top center no-repeat;
      background-size: cover;
      // border: thin solid white;
      border-radius: 60px;
      min-height: 250px;
      overflow: hidden;
      margin: 0 auto;
      opacity: 0;
      position: relative;
      max-width: 500px;
      width: 90%; 

      @media (min-width: 480px) {
        max-width: 400px;
      }

      @media (min-width: 768px) {
        min-height: 400px;
        max-width: 550px;
      }
      
      @media (min-width: 800px) {
        min-height: 400px;
        max-width: 650px;
      }
      
      @media (min-width: 1024px) {
        border-radius: 100px;
        min-height: 350px;
        max-width: 600px;
        width: 60%;
      }
      
      @media (min-width: 1440px) {
        border-radius: 100px;
        min-height: 400px;
        max-width: 720px;
        width: 60%;
      }
    }
    
    &--play {
      background: var(--bs-white-85);
      border-radius: 100%;
      height: 100px;
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      transition: all .35s ease-out;
      width: 100px;

      &:hover {
        background: var(--bs-black);
        cursor: pointer;
      }

      &:after {
        content: '';
          width: 0; 
          height: 0; 
          border-top: 15px solid transparent;
          border-bottom: 15px solid transparent;
          border-left: 20px solid var(--bs-orange);
          left: 55%;
          position: absolute;
          top: 50%;
          transform: translate(-50%, -50%);
      }
    }
  }
}