.bs {
  &-location {    
    position: relative;
    width: 100vw;
    
    &--content {
      // border: thin solid blue;
      opacity: 0;
      position: relative;
      left: -50px;
      margin: 0 auto;
      text-align: center;
      width: 90%;

      @media (min-width: 1024px) {
        margin: 150px auto;
        width: 60%;
      }
      
      @media (min-width: 1440px) {
        margin: 100px auto;
        width: 100%;
      }

      @media (min-width: 1900px) {
        margin: 150px auto;
        width: 60%;
      }

      .bs-action {
        padding: 25px 0 0;
        text-align: center;
      }
    }
    
    &--heading,
    &--header {
      @include AzoSansUber;
      font-size: 36px;
      text-transform: uppercase;

      @media (min-width: 1024px) {
        font-size: 38px;
      }
    }

    &--heading {
      color: var(--bs-orange-dark);
    }
    
    &--header {
      color: var(--bs-orange);
      padding: 0;
    }
  }
}