.bs {
  &-featured {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 50px 0;
    position: relative;
    z-index: 10;

    @media (min-width: 768px) {
      padding: 50px 0 0;
    }

    @media (min-width: 1024px) {
      flex-direction: row;
      padding: 100px 0 100px;
    }
    
    @media (min-width: 2560px) {
      margin: 0 auto;
      width: 85vw;
    }

    &[data-direction="right"] {
      flex-direction: row-reverse;
    }

    &--poster {
      // border: thin solid red;
      height: 300px;
      opacity: 0;
      margin-left: -50px;
      width: 100%;

      @media (min-width: 768px) {
        margin-left: -50px;
        height: 500px;
        width: 70%;
      }
      
      @media (min-width: 840px) {
        margin-left: -50px;
        height: 550px;
        margin: 100px 0 50px;
        width: 70%;
      }

      @media (min-width: 1024px) {
        height: 500px;
        width: 45%;  
      }

      &[data-section='about'] {
        background: url('../images/bs-featured-breakfast-3.png') center center no-repeat;
        background-size: contain;
        position: relative;
        z-index: 2;
      }

      &_sugar {
        background: url('../images/bs-featured-sugar-bg.png') 0 0 no-repeat;
        background-size: contain;
        height: 500px;
        left: 50%;
        opacity: 0;
        position: absolute;
        top: 25%;
        transform: translateX(-50%);
        width: 500px;
        z-index: 1;

        @media (min-width: 1024px) {
          left: 25%;
          height: 850px;
          width: 850px;
        }
      }
    }

    &--content {
      // border: thin solid white;
      margin: 50px auto 0;
      width: 85%;

      @media (min-width: 1024px) {
        left: 0;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 70%;
      }

      .bs-action {
        text-align: left;
        padding: 25px 0;
      }
    }

    &--text {
      // border: thin solid blue;
      color: var(--bs-white); 
      margin-right: -50px;
      opacity: 0;
      position: relative;
      width: 100%;

      @media (min-width: 1024px) {
        padding-inline-end: 150px;
        position: relative;
        width: 45%;
      }

      &[data-direction="right"] {
        padding-inline-end: 0;
        padding-inline-start: 150px;
      }

      &_label {
        @include AzoSansBold;
        font-size: 12px;
        letter-spacing: 1px;
        text-transform: uppercase;

        @media (min-width: 768px) {
          font-size: 18px;
          margin: 10px 0;
        }
      }
      
      &_heading {
        @include AzoSansUber;
        font-size: 24px;
        text-transform: uppercase;
        padding: 10px 0;

        @media (min-width: 768px) {
          font-size: 36px;
          margin: 10px 0;
        }

        @media (min-width: 840px) {
          font-size: 40px;
        }
      }
      
      &_desc {
        @include AzoSansRegular;
        font-size: 14px;
        line-height: 1.5;

        @media (min-width: 768px) {
          font-size: 24px;
        }

        @media (min-width: 840px) {
          font-size: 20px;
          line-height: 1.5;
        }
      }
    }
  }
}