.bs {
  &-shop {
    background: linear-gradient(180deg, var(--bs-white) 0%, var(--bs-yellow) 50%, var(--bs-orange) 100%);
    // border: thin solid red;
    display: flex;
    flex-direction: column;
    padding: 50px 0;
    overflow: hidden;
    position: relative;
    width: 100vw;

    @media (min-width: 1024px) {
      background: linear-gradient(90deg, var(--bs-white) 0%, var(--bs-yellow) 50%, var(--bs-orange) 100%);
      flex-direction: row;
      padding: 400px 0 0;
    }

    &--sugar {
      background: url('../images/bs-sugar-bg-break-2.png') top center no-repeat;
      background-size: cover;
      height: 100vh;
      position: absolute;
      right: 0;
      top: 0;
      width: 100vw;
      z-index: 1;
    }

    &--product {
      // border: thin solid blue;
      margin-left: -50px;
      opacity: 0;
      position: relative;
      width: 100%;
      z-index: 2;

      @media (min-width: 1024px) {
        margin-left: 0;
        left: -50px;
        position: relative;
        width: 50%;
      }

      &_shot {
        height: auto;
        width: 100%;
      }
    }
    
    &--text {
      // border: thin solid blue;
      color: var(--bs-white);
      margin: 0 auto;
      position: relative;
      width: 80%;
      z-index: 2;

      @media (min-width: 1024px) {
        margin: unset;
        width: 50%;
      }

      &_content {
        // border: thin solid blue;
        opacity: 0;

        @media (min-width: 1024px) {
          left: 50%;
          position: absolute;
          top: 50%;
          transform: translate(-50%, -50%);
          width: 70%;
        }
      }

      .bs-action {
        padding: 25px 0 0;
        text-align: left;
      }
    }
    
    &--heading {
      @include AzoSansBold;
      font-size: 14px;
      text-transform: uppercase;

      @media (min-width: 768px) {
        font-size: 16px;
      }

      @media (min-width: 840px) {
        font-size: 18px;
        margin: 10px 0;
      }
    }
    
    &--header {
      @include AzoSansUber;
      padding: 15px 0 0;
      font-size: 28px;

      @media (min-width: 840px) {
        font-size: 40px;
        margin: 0;
        padding: 0;
      }
      
      @media (min-width: 1024px) {
        font-size: 36px;
      }
      
      @media (min-width: 1280px) {
        font-size: 42px;
      }
    }
  }
}