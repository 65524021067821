.bs {
  &-action {
    &--primary {
      @include AzoSansBold;
      background: var(--bs-yellow-light);
      border: 0;
      border-radius: 35px;
      box-shadow: 0 0 50px var(--bs-orange--light);
      color: var(--bs-black);
      display: inline-block;
      font-size: 18px;
      margin: 10px 0;
      padding: 20px 40px;
      position: relative;
      transition: all .35s ease-out;
      text-decoration: none;
      z-index: 21;

      &:hover {
        background: var(--bs-black);
        color: var(--bs-yellow-light);
        cursor: pointer;
      }

      @media (min-width: 768px) {
        font-size: 20px;
        padding: 25px 60px;
      }
      
      @media (min-width: 840px) {
        font-size: 28px;
        padding: 25px 60px;
      }
      
      @media (min-width: 1024px) {
        font-size: 18px;
        padding: 20px 40px;
      }
      
      @media (min-width: 1440px) {
        font-size: 18px;
        padding: 25px 60px;
      }
      
      @media (min-width: 1900px) {
        font-size: 25px;
        padding: 20px 40px;
      }
    }
  }

  &-section--heading {
    @include AzoSansUber;
    background-image: linear-gradient(-180deg, var(--bs-orange-dark), var(--bs-orange--light));
    background-clip: text;
    color: transparent;
    font-size: 36px;
    line-height: 1;
    margin: 0 auto;
    padding: 65px 0 25px;
    text-align: center;
    width: 85%;

    @media (min-width: 768px) {
      font-size: 60px;
    }

    @media (min-width: 1024px) {
      font-size: 75px;
      width: 60%;
    }
    
    @media (min-width: 1280px) {
      font-size: 82px;
      width: 60%;
    }
    
    @media (min-width: 1440px) {
      font-size: 100px;
      width: 65%;
    }
    
    @media (min-width: 1900px) {
      font-size: 120px;
      width: 60%;
    }

    &.large {
      @media (min-width: 1280px) {
        font-size: 100px;
        width: 80%;
      } 

      @media (min-width: 1900px) {
        font-size: 120px;
        width: 90%;
      } 
    }
  }
}