@mixin AzoSansLight {
  font-family: azo-sans-web, sans-serif;
  font-weight: 300;
  font-style: normal;
}

@mixin AzoSansRegular {
  font-family: azo-sans-web, sans-serif;
  font-weight: 400;
  font-style: normal;
}

@mixin AzoSansMedium {
  font-family: azo-sans-web, sans-serif;
  font-weight: 500;
  font-style: normal;
}

@mixin AzoSansBold {
  font-family: azo-sans-web, sans-serif;
  font-weight: 700;
  font-style: normal;
}

@mixin AzoSansUber {
  font-family: azo-sans-uber, sans-serif;
  font-weight: 400;
  font-style: normal;
}