.bs {
  &-social {    
    // border: thin solid red;
    overflow: hidden;
    position: relative;
    width: 100%;

    &--header {
      @include AzoSansUber;
      font-size: 28px;
      color: var(--bs-black);
      text-align: center;
      margin: 0 auto;
      padding: 65px 0 25px;
      width: 80%;
    }

    &--slider {
      background: linear-gradient(90deg, var(--bs-yellow) 0%, var(--bs-white-85) 100%);
      height: 300px;
      opacity: 0;
      overflow: hidden;
      padding: 50px 0;
      position: relative;
      right: -50px;

      &_prev {
        left: 25px;
      }
      
      &_next {
        right: 25px;
      }
      
      &_prev,
      &_next {
        background: var(--bs-white);
        border: 0;
        border-radius: 100%;
        color: var(--bs-black);
        height: 50px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 50px;
        z-index: 7;
      }

      &_content {
        display: flex;
        transition: all .35s ease-out;
        overflow: hidden;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: calc(30 * 300px);
      }

      &_card {
        background: linear-gradient(-180deg, var(--bs-white) 0%, var(--bs-yellow) 40%);
        height: 300px;
        outline: thin solid white;
        position: relative;
        width: 300px;
        z-index: 1;

        &:hover {
          &:before {
            opacity: .75;
          }
        }

        &:before {
          background: var(--bs-white);
          content: '';
          height: 100%;
          left: 0;
          opacity: 0;
          position: absolute;
          top: 0;
          transition: all .35s ease-out;
          width: 100%;
          z-index: 7;
        }

        &[data-media="1"] {
          background: url('../images/social-gallery-photos/bs-social-feed-1.jpg') top center no-repeat;
          background-size: cover;
        }
        
        &[data-media="2"] {
          background: url('../images/social-gallery-photos/bs-social-feed-2.jpg') top center no-repeat;
          background-size: cover;
        }
        
        &[data-media="3"] {
          background: url('../images/social-gallery-photos/bs-social-feed-3.jpg') top center no-repeat;
          background-size: cover;
        }
        
        &[data-media="4"] {
          background: url('../images/social-gallery-photos/bs-social-feed-4.jpg') top center no-repeat;
          background-size: cover;
        }
        
        &[data-media="5"] {
          background: url('../images/social-gallery-photos/bs-social-feed-5.jpg') top center no-repeat;
          background-size: cover;
        }
        
        &[data-media="6"] {
          background: url('../images/social-gallery-photos/bs-social-feed-6.jpg') top center no-repeat;
          background-size: cover;
        }
        
        &[data-media="7"] {
          background: url('../images/social-gallery-photos/bs-social-feed-7.jpg') top center no-repeat;
          background-size: cover;
        }
        &[data-media="8"] {
          background: url('../images/social-gallery-photos/bs-social-feed-8.jpg') top center no-repeat;
          background-size: cover;
        }
        &[data-media="9"] {
          background: url('../images/social-gallery-photos/bs-social-feed-9.jpg') top center no-repeat;
          background-size: cover;
        }
        &[data-media="10"] {
          background: url('../images/social-gallery-photos/bs-social-feed-10.jpg') top center no-repeat;
          background-size: cover;
        }

        &[data-media="11"] {
          background: url('../images/social-gallery-photos/bs-social-feed-11.jpg') top center no-repeat;
          background-size: cover;
        }
        
        &[data-media="12"] {
          background: url('../images/social-gallery-photos/bs-social-feed-12.jpg') top center no-repeat;
          background-size: cover;
        }
        
        &[data-media="13"] {
          background: url('../images/social-gallery-photos/bs-social-feed-13.jpg') top center no-repeat;
          background-size: cover;
        }
        &[data-media="14"] {
          background: url('../images/social-gallery-photos/bs-social-feed-14.jpg') top center no-repeat;
          background-size: cover;
        }
        &[data-media="15"] {
          background: url('../images/social-gallery-photos/bs-social-feed-15.jpg') top center no-repeat;
          background-size: cover;
        }
        &[data-media="16"] {
          background: url('../images/social-gallery-photos/bs-social-feed-16.jpg') top center no-repeat;
          background-size: cover;
        }
        &[data-media="17"] {
          background: url('../images/social-gallery-photos/bs-social-feed-17.jpg') top center no-repeat;
          background-size: cover;
        }
        &[data-media="18"] {
          background: url('../images/social-gallery-photos/bs-social-feed-18.jpg') top center no-repeat;
          background-size: cover;
        }
        &[data-media="19"] {
          background: url('../images/social-gallery-photos/bs-social-feed-19.jpg') top center no-repeat;
          background-size: cover;
        }
        &[data-media="20"] {
          background: url('../images/social-gallery-photos/bs-social-feed-20.jpg') top center no-repeat;
          background-size: cover;
        }
        &[data-media="21"] {
          background: url('../images/social-gallery-photos/bs-social-feed-21.jpg') top center no-repeat;
          background-size: cover;
        }

        &[data-media="22"] {
          background: url('../images/social-gallery-photos/bs-social-feed-22.jpg') top center no-repeat;
          background-size: cover;
        }
        
        &[data-media="23"] {
          background: url('../images/social-gallery-photos/bs-social-feed-23.jpg') top center no-repeat;
          background-size: cover;
        }
        
        &[data-media="24"] {
          background: url('../images/social-gallery-photos/bs-social-feed-24.jpg') top center no-repeat;
          background-size: cover;
        }
        &[data-media="25"] {
          background: url('../images/social-gallery-photos/bs-social-feed-25.jpg') top center no-repeat;
          background-size: cover;
        }
        &[data-media="26"] {
          background: url('../images/social-gallery-photos/bs-social-feed-26.jpg') top center no-repeat;
          background-size: cover;
        }
        &[data-media="27"] {
          background: url('../images/social-gallery-photos/bs-social-feed-27.jpg') top center no-repeat;
          background-size: cover;
        }
        &[data-media="28"] {
          background: url('../images/social-gallery-photos/bs-social-feed-28.jpg') top center no-repeat;
          background-size: cover;
        }
        &[data-media="29"] {
          background: url('../images/social-gallery-photos/bs-social-feed-29.jpg') top center no-repeat;
          background-size: cover;
        }
        &[data-media="30"] {
          background: url('../images/social-gallery-photos/bs-social-feed-30.jpg') top center no-repeat;
          background-size: cover;
        }
      }
    }
  }
}