.bs {
  &-title {
    overflow: hidden;

    &--header {
      display: flex;
      opacity: 0;
      position: relative;
      right: -50px;
    }

    img {
      height: auto; 
      margin: 50px auto 0;
      width: 70%;

      @media (min-width: 1024px) {
        margin: 100px auto 0;
        width: 40%;
      }
      
      @media (min-width: 1440px) {
        margin: 120px auto 20px;
        width: 30%;
      }
    }
  }
}